// 
// tablesaw.scss
//

.tablesaw {
    thead {
        background: lighten($gray-100, 4%);
        background-image: none;
        border: none;
        th {
            text-shadow: none;
        }
        tr:first-child th {
            border: none;
            font-weight: 500;
            font-family: $font-family-secondary;
        }
    }
    td {
        border-top: 1px solid lighten($gray-100, 4%) !important;
    }
}

.tablesaw td,
.tablesaw tbody th {
    font-size: inherit;
    line-height: inherit;
    padding: 10px !important;
}

.tablesaw-stack tbody tr,
.tablesaw tbody tr {
    border-bottom: none;
}

.tablesaw-bar .btn-select.btn-small:after,
.tablesaw-bar .btn-select.btn-micro:after {
    font-size: 8px;
    padding-right: 10px;
}

.tablesaw-swipe .tablesaw-cell-persist {
    box-shadow: none;
    border-color: $gray-100;
}

.tablesaw-enhanced .tablesaw-bar .btn {
    text-shadow: none;
    background-image: none;
    text-transform: none;
    border: 1px solid $gray-300;
    padding: 3px 10px;
    color: $dark;

    &:after {
        display: none;
    }
}

.tablesaw-enhanced .tablesaw-bar .btn.btn-select {
    &:hover {
        background: $white;
    }
}

.tablesaw-enhanced .tablesaw-bar .btn:hover,
.tablesaw-enhanced .tablesaw-bar .btn:focus,
.tablesaw-enhanced .tablesaw-bar .btn:active {
    color: $primary !important;
    background-color: $gray-100;
    outline: none !important;
    box-shadow: none !important;
    background-image: none;
}

.tablesaw-columntoggle-popup {
    label{
        color: $gray-400;
    }
    .btn-group {
        display: block;
    }
}

.tablesaw-swipe .tablesaw-swipe-cellpersist {
    border-right: 2px solid lighten($gray-100, 4%);
}

.tablesaw-sortable-btn {
    cursor: pointer;
}