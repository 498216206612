// 
// taskboard.scss
//

.taskList {
    min-height: 40px;
    margin-bottom: 0;
    > li {
      background-color: lighten($light, 2%);
      border: 1px solid lighten($light, 4%);
      padding: 20px;
      margin-bottom: 15px;
      border-radius: 3px;
  }
  .kanban-detail{
    margin-left: 35px;
    ul {
      li {
        a {
          display: inline-block;
          i {
            font-size: 16px;
            color: $gray-600;
            padding: 0px 5px;
            line-height: 32px;
          }
        }
      }
    }
  }
}


.task-placeholder {
  border: 1px dashed $gray-300 !important;
  background-color: lighten($light, 4%) !important;
  padding: 20px;
}

.gu-mirror {
  list-style: none;
}
